(function () {
  "use strict";

  angular
    .module("app.microsite", [
      "app.common",
      "app.forms",
      "uiGmapgoogle-maps",
      "modules.dataServices",
      "youtube-embed",
      "modules.snapshotRange",
      "ngSanitize",
      "angular-inview",
      "modules.googleMaps",
      "slickCarousel",
      "ngLocationUpdate",
      "ngRoute",
      "modules.device",
      "modules.priceStartingAt",
      "ngTouch",
      "app.login",
      "app.saved",
      "modules.authServices"
    ])

    .config([
      "$interpolateProvider",
      function ($interpolateProvider) {
        $interpolateProvider.startSymbol("<%");
        $interpolateProvider.endSymbol("%>");
      },
    ])

    .config([
      "uiGmapGoogleMapApiProvider",
      function (uiGmapGoogleMapApiProvider) {
        uiGmapGoogleMapApiProvider.configure({
          key: googleApiKey,
          v: "3.25",
          libraries: "places",
        });
      },
    ])

    .config([
      "$locationProvider",
      function ($locationProvider) {
        $locationProvider.html5Mode({
          enabled: false,
          requireBase: true,
          rewriteLinks: false,
        });
      },
    ]);
})();
