/*jslint white: true, nomen: true */
/*global angular, $ */
(function () {
  "use strict";

  angular.module("app.microsite").controller("MicrositeAmenitiesCtrl", [
    "$scope",
    "$q",
    "$timeout",
    "uiGmapPromises",
    "DataService",
    "LocalAmenitiesService",
    "DeviceService",
    "$window",
    function (
      $scope,
      $q,
      $timeout,
      uiGmapPromises,
      DataService,
      LocalAmenitiesService,
      DeviceService,
      $window
    ) {
      var communityLocation = null,
        placesService = null,
        hideWindows = function () {
          // hide any windows
          $scope.map.window.show = false;
          $scope.map.window.marker = null;
          $scope.map.window.model = null;

          if (!$scope.$$phase) {
            $scope.$apply();
          }
        };

      $scope.localAmenities = LocalAmenitiesService.data;
      $scope.map = {
        center: communityCenter,
        control: {},
        markers: [],
        marker: {
          control: {},
          options: {
            icon: {
              anchor: {},
              size: {},
            },
          },
        },
        zoom: 13,
        options: {
          streetViewControl: false,
          draggable: true,
          scrollwheel: false,
          mapTypeControl: false,
          panControl: true,
          zoomControl: true,
        },
        mapEvents: {
          click: function (maps, eventName, args) {
            $("#local-amenities .key").removeClass("active");
            hideWindows();
          },
        },
      };

      var communityId = 3;

      $scope.communityPromise = DataService.getData(
        $window.location.pathname,
        "community"
      ).promise.then(function (community) {
        $scope.community = community;

        var mWidth = 800;
        if (DeviceService.check() == "phone") {
          mWidth = 300;
        }

        uiGmapPromises.GoogleMapsApiReady().then(function (GoogleMaps) {
          angular.extend($scope.map, {
            marker: {
              options: {
                animation: google.maps.Animation.DROP,
                icon: {
                  size: new google.maps.Size(37, 43),
                  anchor: new google.maps.Point(-5, 43),
                },
              },
            },
            options: {
              streetViewControl: false,
              draggable: true,
              scrollwheel: false,
              mapTypeControl: false,
              panControl: true,
              panControlOptions: {
                position: GoogleMaps.ControlPosition.RIGHT_CENTER,
              },
              zoomControl: true,
              zoomControlOptions: {
                position: GoogleMaps.ControlPosition.RIGHT_CENTER,
              },
              styles: mapStyleArray,
            },
            window: {
              marker: {},
              show: false,
              closeClick: function () {
                $("#local-amenities .key").removeClass("active");
                this.show = false;
                this.model = {};
              },
              options: {
                maxWidth: mWidth,
                pixelOffset: new GoogleMaps.Size(-5, -25),
              },
            },
            markersEvents: {
              click: function (marker, eventName, model, args) {
                $("#local-amenities .key").addClass("active");
                $scope.map.window.show = false;
                $scope.map.window.marker = marker;
                $scope.map.window.model = model;
                $scope.map.window.show = true;

                $scope.map.window.options = {
                  maxWidth: mWidth,
                  pixelOffset: new GoogleMaps.Size(24, -25),
                };

                var request = {
                  placeId: model.place_id,
                };

                placesServicePromiseFactory(request, "getDetails")
                  .then(function (details) {
                    model.address = details.formatted_address.replace(
                      ", United States",
                      ""
                    );
                    model.phone = details.formatted_phone_number;

                    model.has_photo = false;
                  })
                  .then(uiGmapPromises.GoogleMapsIsAllReady)
                  .then(function (theMap) {
                    theMap.setCenter({
                      lat: model.latitude,
                      lng: model.longitude,
                    });
                  });
              },
            },
          });

          communityLocation = new GoogleMaps.LatLng(
            $scope.community.latitude,
            $scope.community.longitude
          );

          uiGmapPromises.GoogleMapsIsAllReady().then(function (theMap) {
            // resize trigger
            GoogleMaps.event.addDomListener(window, "resize", function () {
              theMap.setCenter({
                lat: $scope.map.center.latitude,
                lng: $scope.map.center.longitude,
              });
            });

            //appendto key google maps
            $(
              "#local-amenities .angular-google-map-container > .gm-style > div:first-child"
            ).append($("#local-amenities .key"));

            placesService = new GoogleMaps.places.PlacesService(theMap);

            var placesSearches = $scope.localAmenities.map(searchForAmenity);

            // when I have all the promises
            $q.all(placesSearches)
              // after they all resolve, explicitly select "schools", the first amenity in the UI.
              .then(function () {
                $scope.selectAmenity($scope.localAmenities[0]);
              });
            return theMap;
          });
        });
      });

      $scope.selectAmenity = function (amenity) {
        if ($scope.selectedAmenity && amenity == $scope.selectedAmenity) {
          return;
        } else {
          $scope.selectedAmenity = amenity;
        }

        $timeout(function () {
          $(".filters-options li input:checkbox").each(function (
            index,
            checkbox
          ) {
            var checkboxName = $(checkbox).attr("id").substring(16);

            if (checkboxName != $scope.selectedAmenity.name) {
              $(checkbox).prop("checked", false);
            } else {
              $(checkbox).prop("checked", true);
            }
          });
        }, 25);

        // $scope.filters = [];
        // $scope.filters[amenity] = true;

        $scope.map.window.marker = {};
        $scope.map.window.show = false;
        $scope.map.markers = [];

        // Force this to happen in the next $digest
        $timeout(function () {
          $scope.map.markers = angular.copy(amenity.pins);

          var communityMarker = {
            place_id: 1,
            name: $scope.community.name,
            latitude: $scope.community.latitude,
            longitude: $scope.community.longitude,
            options: {
              visible: true,
              icon: {
                url: "/images/site/global/pin-house.png",
                size: new google.maps.Size(46, 67),
                scaledSize: new google.maps.Size(35, 51),
                anchor: new google.maps.Point(-5, 43),
              },
            },
          };

          $scope.map.markers.push(communityMarker);

          fitBoundsWithCurrentMarkers();
        }, 1);
      };

      var addMarkerToMap = function (amenity, searchResult) {
        angular.forEach(searchResult, function (place) {
          var amenity_index = $scope.localAmenities.indexOf(amenity);

          var marker = {
            id: amenity_index + place.place_id,
            place_id: place.place_id,
            name: place.name,
            latitude: place.geometry.location.lat(),
            longitude: place.geometry.location.lng(),
            icon: amenity.icon,
            category: amenity_index,
            options: {
              visible: true,
              icon: {
                url: "/images/site/microsite/amenity-pin.png",
                size: new google.maps.Size(95, 139),
                scaledSize: new google.maps.Size(35, 51),
                anchor: new google.maps.Point(-5, 43),
              },
            },
          };

          $scope.localAmenities[amenity_index].pins.push(marker);
        });
      };

      var fitBoundsWithCurrentMarkers = function () {
        uiGmapPromises.GoogleMapsApiReady().then(function (GoogleMaps) {
          uiGmapPromises.GoogleMapsIsAllReady().then(function (theMap) {
            GoogleMaps.event.trigger(theMap, "resize");

            var newBounds = new GoogleMaps.LatLngBounds();

            angular.forEach($scope.map.markers, function (marker) {
              newBounds.extend(
                new GoogleMaps.LatLng(marker.latitude, marker.longitude)
              );
              newBounds.extend(
                new GoogleMaps.LatLng(
                  0.005 + 1 * marker.latitude,
                  0.005 + 1 * marker.longitude
                )
              );
            });

            theMap.fitBounds(newBounds);

            if (theMap.getZoom() > 13) {
              theMap.setZoom(13);
            }
          });
        });
      };

      // genereates a promise
      // which resolves the result of a Places search
      var placesServicePromiseFactory = function (request, service) {
        if (service === undefined) {
          service = "search";
        }

        return $q(function (resolve, reject) {
          placesService[service](request, function (results, status) {
            if (status === "OK") {
              resolve(results);
            } else {
              reject(status);
            }
          });
        });
      };

      // generates a promise
      // which resolves after adding a marker to the Amenity
      var searchForAmenity = function (amenity) {
        var request = makeRequestObjectFromAmenity(amenity);

        return placesServicePromiseFactory(request)
          .then(function (results) {
            addMarkerToMap(amenity, results);
          })
          .catch(function (status) {
            // Remove the amenity from the list if we don't find any results
            $scope.localAmenities.splice(
              $scope.localAmenities.indexOf(amenity),
              1
            );
          });
      };

      // returns an object
      // for use as a Request to Places Search
      var makeRequestObjectFromAmenity = function (amenity) {
        return {
          location: communityLocation,
          radius: 8000,
          types: amenity.types,
        };
      };
    },
  ]);
})();
