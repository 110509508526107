/*jslint white: true, nomen: true */
/*global angular */
(function () {
  "use strict";

  angular.module("app.microsite").controller("MicrositeLocationCtrl", [
    "$scope",
    "$timeout",
    "$window",
    "DataService",
    "uiGmapPromises",
    function ($scope, $timeout, $window, DataService, uiGmapPromises) {
      $scope.map = {
        center: communityCenter,
        control: {},
        markers: [],
        zoom: 15,
        windows: [],
        markersEvents: {
          click: function (marker, eventName, model, args) {
            $scope.map.windows[model.id].show = true;
          },
        },
      };

      $scope.communityPromise = DataService.getData(
        $window.location.pathname,
        "community"
      ).promise.then(function (community) {
        $scope.community = community;

        uiGmapPromises.GoogleMapsApiReady().then(function (GoogleMaps) {
          angular.extend($scope.map, {
            options: {
              streetViewControl: false,
              draggable: true,
              scrollwheel: true,
              mapTypeControl: false,
              panControl: true,
              panControlOptions: {
                position: GoogleMaps.ControlPosition.LEFT_CENTER,
              },
              zoomControl: true,
              zoomControlOptions: {
                position: GoogleMaps.ControlPosition.RIGHT_BOTTOM,
              },
            },
          });

          var lat = $scope.community.latitude,
            lng = $scope.community.longitude,
            name = $scope.community.name;

          angular.extend($scope.map, {
            center: {
              latitude: lat,
              longitude: lng,
            },
          });

          $scope.map.markers.push({
            id: 0,
            latitude: lat,
            longitude: lng,
            icon: "/images/site/microsite/map-pin-contact.png",
            options: {
              visible: true,
            },
          });

          $scope.map.windows.push({
            id: 0,
            latitude: lat,
            longitude: lng,
            show: true,
            templateUrl: "contactPopupWindow.html",
            templateParameter: {
              name: name,
              latitude: lat,
              longitude: lng,
            },
            options: {
              maxWidth: 800,
              pixelOffset: new GoogleMaps.Size(0, -7.5),
            },
          });
        });
      });

      $scope.micrositeContactAnalyticsConfig = {
        googleAnalytics: {
          pageViewUrl: "/ajax-goals/microsite-contact",
        },
      };
    },
  ]);
})();
